import React, {useContext, useEffect, useState} from 'react';
import Modal from './Model';
import TodoForm from "./TodoForm";
import {TodoContext} from "../context";
import {calendarItems} from "../constants";
import firebase from "../firebase";
import moment from "moment";
import randomcolor from "randomcolor"

function AddNewTodo() {

    /* context */
    const{projects,selectedProject}= useContext(TodoContext);

    /* state */
    const [showModal,setShowModel] = useState(false);
    const [text,setText] = useState('');
    const [day,setDay] = useState(new Date());
    const [time,setTime] = useState(new Date());
    const [todoProject,setTodoProject] = useState(selectedProject);

    function handleSubmit(e) {
        e.preventDefault();
        if (text && !calendarItems.includes(todoProject)){
            firebase.firestore()
                .collection('todos')
                .add({
                    text:text,
                    date:moment(day).format('MM/DD/YYYY'),
                    day:moment(day).format('d'),
                    time:moment(time).format('hh:mm A'),
                    checked: false,
                    color:randomcolor({luminosity:'dark'}),
                    projectName:todoProject
                }
            )
            setShowModel(false);
            setText('');
            setDay(new Date());
            setTime(new Date());

        }


    }
    useEffect( () => {
        setTodoProject(selectedProject);
    },[selectedProject])


    return (
        <div className="AddNewTodo">
            <div className="button1">
                <button onClick={() => setShowModel(true)}>
                    + New Todo
                </button>
            </div>
            <Modal showModal={showModal} setShowModel={setShowModel} >
               <TodoForm
                handleSubmit={handleSubmit}
                heading="Add new To do"
                text={text}
                setText={setText}
                day={day}
                setDay={setDay}
                time={time}
                setTime={setTime}
                todoProject={todoProject}
                setTodoProject={setTodoProject}
                projects={projects}
                showButtons={true}
                setShowModel={setShowModel}
               />
            </Modal>
        </div>
    );
};

export default AddNewTodo;
import React from 'react';

function Main({children}) {
    return (
        <div className="Main">
            {children}
        </div>
    );
};

export default Main;
import firebase from 'firebase/app';
import "firebase/firestore";

const firebaseConfig = {
apiKey: "AIzaSyA-MMN8uHdQR6E7pigbgtokqwnvZ6QfnUk",
authDomain: "beef-todo-app.firebaseapp.com",
projectId: "beef-todo-app",
storageBucket: "beef-todo-app.appspot.com",
messagingSenderId: "1066723013358",
appId: "1:1066723013358:web:0c031896849c554d17b9ff"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase
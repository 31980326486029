import React from 'react';
import logo from '../images/logo.jpg'

function User() {
    return (
        <div className="User">
            <div className="Logo">
                <img src={logo} alt="Dit is mijn logo" />
            </div>
            <div className="Info" >
                <p>Arnold Dubbelman</p>
                <a href="#">Logout</a>
            </div>

        </div>
    );
};

export default User;
import React, {useRef} from 'react'
import {useSpring,animated} from "react-spring";

function Model({ children, showModal, setShowModel}) {

    const modalRef = useRef();

    function closeModel(e){
        if (modalRef.current === e.target ) {
            setShowModel(false);
        }
    }

    // Animation
const modalAnimation = useSpring({
    opacity : showModal ? 1 : 0,
    top : showModal ? '25%' : '0%',
    config : {friction:10}
})


    return(
        showModal &&
        <div className="Model" ref={modalRef} onClick={closeModel}>
            <animated.div style={modalAnimation} className="container" >
                {children}
            </animated.div>
        </div>
    )
}
export default Model
import React, {useContext, useState} from 'react';
import RenameProject from "./RenameProject";
import {Pencil, XCircle} from "react-bootstrap-icons";
import Model from "./Model";
import {TodoContext} from "../context";
import firebase from "../firebase";
import {useTransition,useSpring,animated} from "react-spring";

function Project({project,edit}) {

    const {selectedProject,setSelectedProject,defaultProject} = useContext(TodoContext);

    const [showModal,setShowModel] = useState(false);

    //Animation
    const fadeIn = useSpring({
        from : {marginTop : '-12px' , opacity : 0},
        to: {marginTop : '0px', opacity :1}
    })

    const btnTransitions = useTransition(edit,{
        from : {opacity : 0 , right : '-20px'},
        enter : {opacity : 1 , right : '0px'},
        leave : {opacity : 0 , right : '-20px'},

    })

    const deleteProject = (project) => {
        firebase.firestore().collection('projects')
            .doc(project.id).delete()
            .then( () => {
                firebase.firestore().collection('todos').where('projectName', '==',project.name)
                    .get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach(doc => {
                            doc.ref.delete();
                        })
                    })
            })
            .then(() => {
                if (selectedProject === project.name) {
                    setSelectedProject(defaultProject);
                }
            });
    }

    return (
        <animated.div style={fadeIn} className="Project">
            <div className='name' onClick={() => setSelectedProject(project.name)}>
                {project.name}
            </div>
            <div className='btns'>
                {
                    btnTransitions((props,edit) =>
                        edit ?
                            <animated.div style={props} className='edit-delete'>
                            <span className='edit' onClick={() => setShowModel(true)}>
                                <Pencil size='13'/>
                            </span>
                                <span className='delete'
                                      onClick={() => deleteProject(project)}
                                >
                                <XCircle size='13'/>
                            </span>
                            </animated.div>
                            :
                            project.numOfTodos > 0 ?
                                <animated.div style={props} className='total-todos'>
                                    {project.numOfTodos}
                                </animated.div>
                                :
                                ""
                    )
                }
            </div>
            <Model showModal={showModal} setShowModel={setShowModel} >
                <RenameProject project={project} setShowModel={setShowModel} />
            </Model>
        </animated.div>
    );
};

export default Project;
import React from 'react';
import {Bell, CalendarDay, Clock, Palette, X} from "react-bootstrap-icons";
import { MuiPickersUtilsProvider, DatePicker,TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

function TodoForm({
    handleSubmit,
    heading=false,
    text, setText,
    day, setDay,
    time, setTime,
    todoProject,setTodoProject,
    projects,
    showButtons=false,
    setShowModel=false
                  }) {

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} >
            <form onSubmit={handleSubmit} className='TodoForm'>
                <div className="text">
                    {
                        heading &&
                        <h3>{heading}</h3>
                    }
                    <input
                        type="text"
                        value={text}
                        onChange={event => setText(event.target.value) }
                        placeholder='Todo..'
                        autoFocus
                    />
                </div>
                <div className='remind'>
                    <Bell />
                    <p>Remind Me</p>
                </div>
                <div className="pick-day">
                    <div className='title'>
                        <CalendarDay />
                        <p>Choose a day</p>
                    </div>
                    <DatePicker
                        value={day}
                        onChange={day => setDay(day)}
                    />
                </div>
                <div className="pick-time">
                    <div className='title'>
                        <Clock />
                        <p>Choose a time</p>
                    </div>
                    <TimePicker
                        value={time}
                        onChange={time => setTime(time)}
                    />
                </div>
                <div className="pick-project" >
                    <div className='title'>
                        <Palette />
                        <p>Choose a project</p>
                    </div>
                    <div className='projects'>
                        {
                            projects.length > 0 ?
                            projects.map(project =>
                                <div
                                    className={`project ${todoProject === project.name ? "active" : ""}`}
                                    key={project.id}
                                    onClick={() => setTodoProject(project.name)}

                                >
                                    {project.name}
                                </div>

                            ) :
                                <div style={{color:'red'}}>
                                    Please add a project before proceeding
                                </div>
                        }
                    </div>
                    {
                        showButtons &&
                        <div>
                            <div className='cancel' onClick={ () => setShowModel(false)}>
                                < X size='40' />
                            </div>
                            <div className='confirm'>
                            <button>+ Add todo</button>
                            </div>
                        </div>
                    }

                </div>
            </form>
        </MuiPickersUtilsProvider>
    );
};

export default TodoForm;
import React, {createContext,useState} from 'react';
import {useProjects, useTodos, useProjectsWithStats, useFilterTodos, useFilteredTodos} from "../hooks";

const TodoContext = createContext()

function TodoContextProvider({children}) {

    const defaultProject = 'today';
    const [selectedProject,setSelectedProject] = useState(defaultProject)
    const [selectedTodo,setSelectedTodo] = useState(undefined)
    const todos = useTodos();
    const projects = useProjects();
    const projectsWithStats = useProjectsWithStats(projects,todos);
    const filteredTodos = useFilteredTodos(todos,selectedProject);

    return (
        <TodoContext.Provider value={
            {
                selectedProject,
                setSelectedProject,
                todos:filteredTodos,
                projects:projectsWithStats,
                defaultProject,
                selectedTodo,
                setSelectedTodo
            }
        }>
            {children}
        </TodoContext.Provider>
    )
}

export {TodoContextProvider,TodoContext}
import React, {useContext, useState} from 'react';
import ProjectForm from "./ProjectForm";
import firebase from "firebase";
import {TodoContext} from "../context";

function RenameProject({project,setShowModel}) {

    const [newProjectNewName,setNewProjectName] = useState(project.name);
    const {selectedProject,setSelectedProject} = useContext(TodoContext);

    const renameProject = (project,newProjectNewName) => {
        const projectsRef = firebase.firestore().collection('projects');
        const todosRef = firebase.firestore().collection('todos');



        const {name:oldProjectName} = project ;
        projectsRef.where('name','==',newProjectNewName)
            .get()
            .then(querySnapshot => {
                if (!querySnapshot.empty){
                    alert('Project with the same name already exists');
                }else {
                    projectsRef.doc(project.id)
                        .update(
                            {
                                name:newProjectNewName
                            }
                        ).then(() => {
                        todosRef.where('projectName','==',oldProjectName)
                            .get()
                            .then(querySnapshot => {
                                querySnapshot.forEach(todo => {
                                    todo.ref.update({
                                        projectName:newProjectNewName
                                    })
                                })
                            }).then(() =>{
                                if (selectedProject === oldProjectName) {
                                    setSelectedProject(newProjectNewName);
                                }
                        })

                    })
                }
            })

    }

    function handleSubmit(e) {
        e.preventDefault();
        renameProject(project,newProjectNewName);
        setShowModel(false);
    }

    return (
        <div className="RenameProject">
            <ProjectForm
                handleSubmit={handleSubmit}
                heading='Edit project name'
                value={newProjectNewName}
                setValue={setNewProjectName}
                setShowModel={setShowModel}
                confirmButtonText="Confirm"
            />
        </div>
    );
};

export default RenameProject;
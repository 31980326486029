import React, {useState} from 'react';
import {Plus} from "react-bootstrap-icons";
import Model from "./Model";
import ProjectForm from "./ProjectForm";
import firebase from "../firebase";

function AddNewProject() {

    const [showModal,setShowModel] = useState(false);
    const [projectName,setProjectName] = useState('')

    function handleSubmit(e){
        e.preventDefault();

        if (projectName) {
            const projectRef = firebase.firestore().collection('projects');

            projectRef.where('name' ,'==', projectName)
                .get()
                .then( querySnapshot =>
                    {
                        if (querySnapshot.empty) {
                            projectRef.add(
                                {
                                    name:projectName
                                }
                            )
                        }else {
                            alert('Project already exists')
                        }
                    }
                )
            setShowModel(false);
            setProjectName('')
        }
    }

    return (
        <div className="AddNewProject">
            <div className='add-button' onClick={() => setShowModel(true)}>
                <span>
                    <Plus size='20' />
                </span>
            </div>
            <Model showModal={showModal} setShowModel={setShowModel}>
                <ProjectForm
                    handleSubmit={handleSubmit}
                    heading='New Project'
                    value={projectName}
                    setValue={setProjectName}
                    setShowModel={setShowModel}
                    confirmButtonText="+ Add Project"
                />
            </Model>
        </div>
    );
};

export default AddNewProject;